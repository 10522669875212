import React from 'react'
import { Box, BoxProps } from "@mui/material";
import type { FabProps } from "@mui/material/Fab";

import Fab from "@mui/material/Fab";
import { useCallback } from "react";
import Iconify from '../iconify';

// ----------------------------------------------------------------------
export type ScrollToButtonProps = FabProps & {
  side?: "top" | "bottom";
  isVisible: boolean;
  containerRef?: React.RefObject<HTMLElement>;
  boxSx?: BoxProps["sx"];
};

export function ScrollToButton({
  side = "top",
  containerRef,
  isVisible,
  sx,
  boxSx,
  ...other
}: ScrollToButtonProps) {
  const handleScroll = useCallback(() => {
    if (containerRef?.current) {
      const container = containerRef.current;
      container.scrollTo({
        top: side === "top" ? 0 : container.scrollHeight,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: side === "top" ? 0 : document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <Box
      sx={{
        mr: 2,
        position: "absolute",
        bottom: isVisible ? 16 : 0,
        right: "50%",
        left: "45%",
        zIndex: 2,
        width: 40,
        height: 40,
        transition: "all 250ms ease-in",
        opacity: isVisible ? "1" : "0",
        visibility: isVisible ? "visible" : "hidden",
        ...boxSx,
      }}
    >
      <Fab
        aria-label={`Back to top ${side}`}
        onClick={handleScroll}
        color="secondary"
        sx={{
          width: 40,
          height: 40,
          flex: "0 0 40px",
          position: "fixed",
          color: "text.primary",
          zIndex: (theme) => theme.zIndex.speedDial,
          transition: (theme) => theme.transitions.create(["transform"]),
          ...sx,
        }}
        {...other}
      >
        <Iconify
          width={20}
          icon={
            side === "bottom"
              ? "solar:alt-arrow-down-line-duotone"
              : "solar:alt-arrow-up-line-duotone"
          }
        />
      </Fab>
    </Box>
  );
}
