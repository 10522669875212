import ReactMarkdown from "react-markdown";

import { styled } from "@mui/material/styles";

import { alpha } from "@mui/system";
import { markdownClasses } from "./classes";

// ----------------------------------------------------------------------

const MARGIN = "0.75em";

export const StyledRoot = styled(ReactMarkdown)(({ theme }) => ({
  "> * + *": {
    marginTop: 0,
    marginBottom: MARGIN,
  },
  "*": {
    fontFamily: theme.typography.fontFamily,
  },
  /**
   * Heading & Paragraph
   */
  h1: {
    ...theme.typography.h1,
    fontSize: "26px !important",
    marginTop: 0,
    marginBottom: 8,
  },
  h2: {
    ...theme.typography.h2,
    fontSize: "24px !important",
    marginTop: 0,
    marginBottom: 8,
  },
  h3: {
    ...theme.typography.h3,
    fontSize: "22px !important",
    marginTop: 0,
    marginBottom: 8,
  },
  h4: {
    ...theme.typography.h4,
    fontSize: "20px !important",
    marginTop: 0,
    marginBottom: 8,
  },
  h5: {
    ...theme.typography.h5,
    fontSize: "18px !important",
    marginTop: 0,
    marginBottom: 8,
  },
  h6: {
    ...theme.typography.h6,
    fontSize: "16px !important",
    marginTop: 0,
    marginBottom: 8,
  },
  p: {
    ...theme.typography.body1,
    fontSize: "14px !important",
    marginVertical: "4px",
  },
  /**
   * Hr Divider
   */
  hr: {
    flexShrink: 0,
    borderWidth: 0,
    margin: "2em 0",
    msFlexNegative: 0,
    WebkitFlexShrink: 0,
    borderStyle: "solid",
    borderBottomWidth: "thin",
    borderColor: theme.palette.divider,
  },
  /**
   * Image
   */
  [`& .${markdownClasses.content.image}`]: {
    width: "100%",
    height: "auto",
    maxWidth: "100%",
    margin: "auto auto 1.25em",
  },
  /**
   * List
   */
  "& ul": {
    listStyleType: "disc",
  },
  "& ul, & ol": {
    paddingLeft: 30,
    fontFamily: theme.typography.fontFamily,
    "& > li": {
      lineHeight: 2,
      fontFamily: theme.typography.fontFamily,
      fontSize: "14px !important",
      "& > p": {
        margin: 0,
        display: "block",
        fontFamily: theme.typography.fontFamily,
      },
    },
  },
  /**
   * Blockquote
   */
  "& blockquote": {
    lineHeight: 1.5,
    fontSize: "1.5em",
    margin: "24px auto",
    position: "relative",
    fontFamily: "Georgia, serif",
    padding: theme.spacing(3, 3, 3, 8),
    color: theme.palette.text.secondary,
    borderLeft: `solid 8px ${alpha(theme.palette.grey["500"], 0.08)}`,
    [theme.breakpoints.up("md")]: {
      width: "100%",
      maxWidth: 640,
    },
    "& p": {
      margin: 0,
      fontSize: "inherit",
      fontFamily: "inherit",
    },
    "&::before": {
      left: 16,
      top: -8,
      display: "block",
      fontSize: "3em",
      content: '"\\201C"',
      position: "absolute",
      color: theme.palette.text.disabled,
    },
  },
  /**
   * Code inline
   */
  [`& .${markdownClasses.content.codeInline}`]: {
    padding: theme.spacing(0.25, 0.5),
    color:
      theme.palette.warning[
        theme.palette.mode === "light" ? "darker" : "lighter"
      ],
    backgroundColor:
      theme.palette.warning[
        theme.palette.mode === "light" ? "lighter" : "darker"
      ],
    fontSize: theme.typography.body2.fontSize,
    borderRadius: theme.shape.borderRadius / 2,
  },
  /**
   * Code Block
   */
  [`& .${markdownClasses.content.codeBlock}`]: {
    position: "relative",
    width: "100%",
    "& pre": {
      overflowX: "auto",
      padding: theme.spacing(2),
      scrollbarWidth: "thin",
      scrollbarColor: `${alpha(theme.palette.text.disabled, 0.4)} ${alpha(
        theme.palette.text.disabled,
        0.08
      )}`,
      color: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
      fontFamily: "'JetBrainsMono', monospace",
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 800 : 700],
      "& code": {
        fontFamily: "'JetBrainsMono', monospace",
        fontSize: theme.typography.body2.fontSize,
        backgroundColor: "transparent",
        color: theme.palette.common.white,
        "&.hljs": { padding: theme.spacing(0.75) },
        scrollbarWidth: "thin",
      },
    },
  },
  /**
   * Table
   */
  table: {
    width: "100%",
    maxWidth: "100%",
    borderCollapse: "collapse",
    border: `1px solid ${theme.palette.divider}`,
    "th, td": {
      padding: theme.spacing(1),
      border: `1px solid ${theme.palette.divider}`,
    },
    "tbody tr:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.neutral,
    },
  },
  /**
   * Checkbox
   */
  input: {
    "&[type=checkbox]": {
      position: "relative",
      cursor: "pointer",
      "&:before": {
        content: '""',
        top: -2,
        left: -2,
        width: 17,
        height: 17,
        borderRadius: 3,
        position: "absolute",
        backgroundColor:
          theme.palette.grey[theme.palette.mode === "light" ? 300 : 700],
      },
      "&:checked": {
        "&:before": { backgroundColor: theme.palette.primary.main },
        "&:after": {
          content: '""',
          top: 1,
          left: 5,
          width: 4,
          height: 9,
          position: "absolute",
          transform: "rotate(45deg)",
          msTransform: "rotate(45deg)",
          WebkitTransform: "rotate(45deg)",
          border: `solid ${theme.palette.common.white}`,
          borderWidth: "0 2px 2px 0",
        },
      },
    },
  },
}));
