import { useCallback, useEffect, useState } from "react";

import { useDebounce } from "./use-debounce";
import useMessagesScroll from "./use-message-scroll";
export interface UseScrollManagementParams {
  messages: any[];
  isTyping: boolean;
  debounceTime?: number;
  atBottomscrollOffset?: number; // Offset for determining "at bottom"
  showScrollOffset?: number;
}

export interface UseScrollManagementResult {
  messagesEndRef: React.RefObject<HTMLDivElement>;
  showScrollToBottom: boolean;
  isAutoScrollEnabled: boolean;
}

export function useScrollManagement({
  messages,
  isTyping,
  debounceTime = 200,
  atBottomscrollOffset = 150,
  showScrollOffset = 600,
}: UseScrollManagementParams): UseScrollManagementResult {
  const debouncedMessagesLength = useDebounce(messages, debounceTime);
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const { messagesEndRef } = useMessagesScroll(
    debouncedMessagesLength,
    !isAutoScrollEnabled
  );

  const handleScroll = useCallback(() => {
    if (!messagesEndRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = messagesEndRef.current;
    const isAtBottom =
      Math.abs(scrollHeight - scrollTop - clientHeight) < atBottomscrollOffset;

    if (isAtBottom) {
      setIsAutoScrollEnabled(true);
      setShowScrollToBottom(false);
    } else {
      setIsAutoScrollEnabled(false);
      if (scrollHeight - scrollTop - clientHeight > showScrollOffset) {
        setShowScrollToBottom(true);
      }
    }
  }, [messagesEndRef, atBottomscrollOffset, showScrollOffset]);

  useEffect(() => {
    const messagesContainer = messagesEndRef.current;

    if (messagesContainer) {
      messagesContainer.addEventListener("scroll", handleScroll, {
        passive: true,
      });
    }
    return () => {
      if (messagesContainer) {
        messagesContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll, messagesEndRef?.current]);

  useEffect(() => {
    if (messagesEndRef.current && isAutoScrollEnabled && isTyping) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messages, isAutoScrollEnabled, isTyping]);

  return {
    messagesEndRef,
    showScrollToBottom,
    isAutoScrollEnabled,
  };
}
